<template>
	<el-form class="page" :model="obavestenja" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
				<el-form-item :label="$t('obavestenja.naslov')" prop="naslov">
					<el-input v-model="obavestenja.naslov" maxlength="200" show-word-limit :disabled="id > 0"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
				<el-form-item :label="$t('obavestenja.tekst')" prop="tekst">
					<el-input type="textarea" :rows="10" maxlength="5000" show-word-limit :disabled="id > 0" v-model="obavestenja.tekst"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
				<el-form-item :label="$t('obavestenja.slika')" prop="slika">
					<input type="file" ref="fileupload" accept="image/*" @change="onChange" :hidden="id > 0"/>
					<div id="preview">
						<el-image style="width: 300px; height: 300px" v-if="obavestenja.base64" :src="obavestenja.base64"></el-image>
						<el-image style="width: 300px; height: 300px" v-if="obavestenja.slika_url" :src="obavestenja.slika_url"></el-image>
					</div>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
				<el-form-item :label="$t('obavestenja.link')" prop="link">
					<el-input v-model="obavestenja.link" :disabled="id > 0"/>
				</el-form-item>

				<el-form-item :label="$t('obavestenja.linkDesc')" v-if="obavestenja.link.length !== 0" prop="link_desc">
					<el-input v-model="obavestenja.link_desc" :disabled="id > 0"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item v-if="id > 0" size="large">
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.nazad') }}</el-button>
				</el-form-item>
				<el-form-item v-else size="large">
					<el-button v-loading="onSubmitLoading" :disabled="onSubmitLoading" type="success" @click="onSubmit">{{ id > 0 ? $t('obavestenja.objavi') : $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'obavestenja-edit',
	data() {
		let validateLink = (rule, value, callback) => {
			if (value > '') {
				let valid = new RegExp(/^(ftp|http|https):\/\/(www\.)[^ "]+$/).test(value);
				if (!valid)
					callback(new Error());
			}
			callback();
		};
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			daniLista: [1, 2, 3, 4, 5, 6, 7],
			obavestenja: {
				naslov: '',
				tekst: '',
				base64: '',
				slika_url: '',
				link: '',
				link_desc: ''
			},
			rules: {
				naslov: [{ required: true, max: 200, trigger: 'blur', message: this.$t('message.obavezno') }],
				tekst: [{ required: true, max: 5000, trigger: 'blur', message: this.$t('message.obavezno') }],
				link: [
					{ validator: validateLink, trigger: 'blur', message: this.$t('obavestenja.format') }
				],
				link_desc: [
					{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }
				]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('obavestenja.obavestenje')
		});

		this.$utils('stopLoadingAfter', [this.getObavestenja()]);
	},
	methods: {
		onChange(e) {
			const image = e.target.files[0];

			const isJPG = image.type === 'image/jpeg';
			const isLt2M = image.size / 1024 / 1024 <= 2;

			if (!isJPG) {
				this.$refs.fileupload.value = null;
				return this.$message.error('Slika mora da bude u JPG formatu!');
			}

			if (!isLt2M) {
				this.$refs.fileupload.value = null;
				return this.$message.error('Slika mora da bude manja od 2MB!');
			}


			const reader = new FileReader();
			reader.readAsDataURL(image);
			reader.onload = e => {
				this.obavestenja.base64 = e.target.result;
			};
		},
		getObavestenja() {
			if (!this.formCreate) {
				return this.$get('obavestenja', { id: this.id }).then(data => {
					this.obavestenja = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				if (this.obavestenja.link.length === 0)
					this.obavestenja.link_desc = '';

				if (this.obavestenja.link_desc.length === 0)
					this.obavestenja.link = '';

				let data = JSON.stringify(this.obavestenja);

				if (this.formCreate)
					this.$save('obavestenja', data);
				else
					this.$update('obavestenjaObjavi', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	}
};
</script>

<style>
.obavestenje .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	/*position: relative;*/
	margin-left: 10px;
	margin-bottom: 10px;
	overflow: hidden;
}

.obavestenje .el-upload:hover {
	border-color: #409EFF;
}

.obavestenje-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
</style>
